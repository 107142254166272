<template>
  <div class="forBox" v-if="forget">
    <img
      @click="close"
      class="backImg"
      src="../assets/img/Group4337.png"
      alt=""
    />
    <div class="forMain">
      <h2>忘记密码</h2>
      <div class="tabMain">
        <!-- 手机号 -->
        <div class="phone">
          <span class="num">+86</span>
          <i class="xian"></i>
          <input
            class="auth"
            type="text"
            placeholder="请输入手机号"
            v-model="mobile"
          />
          <!-- <span class="gain">获取验证码</span> -->
          <input
            class="note"
            type="text"
            placeholder="请输入短信验证码"
            v-model="code"
          />
          <span class="gain" v-if="sendcode == 0">获取验证码</span>
          <span class="gain-g" v-if="sendcode == 1" @click="sendsms"
            >获取验证码</span
          >
          <span class="gain-x" v-if="sendcode == 2">{{ time }}秒</span>
        </div>
        <input
          class="tel2"
          type="text"
          placeholder="请输入密码"
          v-model="cipher"
        />
        <input
          class="pass"
          type="text"
          placeholder="再次输入密码"
          v-model="ciphers"
        />
      </div>
      <button class="enter" @click="que">确定</button>
      <div class="onceEnter" @click="deng">立即登录</div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/index";
export default {
  props: ["isshow"],
  data() {
    return {
      mobile: "",
      code: "",
      timer: "",
      time: "0",
      sendcode: "0",
      cipher: "",
      ciphers: "",
      forget: this.isshow,
    };
  },
  methods: {
    close() {
      this.forget = false;
    },
    deng() {
      this.forget = false;
    },

    async que() {
      console.log("点击了确定----");
      if (this.mobile == "") {
        this.$notify.error({
          title: "错误",
          message: "请填写你的手机号码",
          duration: 2000,
        });
        return;
      }
      if (this.code == "") {
        this.$notify.error({
          title: "错误",
          message: "请输入验证码",
          duration: 2000,
        });
        return;
      }
      if (this.cipher == "") {
        this.$notify.error({
          title: "错误",
          message: "请输入密码",
          duration: 2000,
        });
        return;
      }
      if (this.ciphers == "") {
        this.$notify.error({
          title: "错误",
          message: "请再次输入密码",
          duration: 2000,
        });
        return;
      }
      if (this.ciphers != this.cipher) {
        this.$notify.error({
          title: "错误",
          message: "两次密码输入不一致",
          duration: 2000,
        });
      }

      let change = await api.passwordchange(this.mobile,this.code,this.cipher);

      if(change.code == 1){
        this.$notify.success({
          title: "成功",
          message: change.message,
          duration: 2000,
        });
      }else{
        this.$notify.error({
          title: "错误",
          message: change.message,
          duration: 2000,
        });
      }

    },
    async sendsms() {
      let res = await api.forgetmobile(this.mobile);

      if (res.code == 1) {
        const TIME_COUNT = 60;
        if (this.time == 0) {
          this.time = TIME_COUNT;
          this.sendcode = 2;
          this.timer = setInterval(() => {
            if (this.time > 0 && this.time <= TIME_COUNT) {
              this.time--;
            } else {
              if (this.mobile.length == 11) {
                this.sendcode = 1;
              } else {
                this.sendcode = 0;
              }
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } else {
        this.$notify.error({
          title: "错误",
          message: res.message,
          duration: 2000,
        });
        return;
      }
    },
  },
  watch: {
    isshow(newval) {
      console.log("----", newval);
      this.forget = newval;
    },

    mobile(newval) {
      if (this.time == 0) {
        if (newval.length == 11) {
          this.sendcode = 1;
        } else {
          this.sendcode = 0;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.forBox {
  display: flex;
  // float: right;
  width: 600px;
  height: 840px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: center;
}
.backImg {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 28px;
  left: 24px;
}
.forMain {
  width: 360px;
  height: 300px;
  // background-color: grey;
  margin-top: 160px;
}
.tabMain {
  margin-top: 24px;
  line-height: 35px;
  position: relative;
}
.num {
  position: absolute;
  top: 3px;
  left: 12px;
  font-size: 14px;
}
.xian {
  width: 1px;
  height: 12px;
  background-color: #c4c4c4;
  position: absolute;
  top: 14px;
  left: 30px;
}
.tel,
.auth,
.tel2,
.pass {
  width: 360px;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
}
.tel {
  padding-left: 60px;
}
.tel2,
.pass {
  padding-left: 12px;
  margin-top: 24px;
}
.cuoImg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 13px;
}
input {
  outline: none;
}
.auth {
  padding-left: 70px;
}
.gain {
  width: 102px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  border-radius: 0px 8px 8px 0px;
  position: absolute;
  top: 56px;
  right: 0px;
  box-sizing: border-box;
  color: #fff;
}
.enter {
  width: 360px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  margin-top: 24px;
  cursor: pointer;
  color: #fff;
  background-color: #2c68ff;
  border: 0;
}
.onceEnter {
  text-align: center;
  margin-top: 16px;
  color: #2c68ff;
  cursor: pointer;
}

.phone {
  margin-top: 16px;
  position: relative;
}
.auth {
  padding-left: 70px;
  width: 360px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.gain,
.gain-x {
  width: 102px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 56px;
  right: 0px;
  box-sizing: border-box;
  color: #fff;
}

.gain-g {
  width: 102px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background-color: #2c68ff;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 56px;
  right: 0px;
  box-sizing: border-box;
  color: #fff;
}
.note {
  width: 245px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  margin-top: 16px;
  box-sizing: border-box;
}
</style>