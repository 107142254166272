<template>
    <div class="scanBox" v-if="weicode">
        <div class="scanMain">
            <img class="cuoImg" @click="close" src="../assets/img/close.png" alt="">
            <div class="scanTitle">微信扫码登录</div>
            <div class="scanMa">
            <vue-qr class="vue-qr" :text="url" :logoScale="50" :size="300"></vue-qr>
            </div>
            <div class="scanHint">{{title}}</div>
        </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import * as api from "../api/index";
import Cookies from 'js-cookie';
    let timer = '';
    export default {
        props:['iscode'],
        components:{VueQr},
        data() {
            return {
                weicode:this.iscode,
                url:'',
                state:'',
                title:'微信扫描二维码登录'
            }
        },
        methods: {
            close() {
                this.weicode = false;
                clearInterval(timer);
                this.title = '微信扫描二维码登录';
            },
            async getcodeurl()
            {
                let ret = await api.getCodeUrl();
                this.url = ret.data.url;
                this.state = ret.data.state;
                let that = this;
                if(this.state != '' && this.url != ''){
                    timer = setInterval(function(){
                        that.getState()
                    },3000)
                }

            },
            async getState()
            {
                let res = await api.getState(this.state);

                if(res.code == 1){
                    clearInterval(timer);
                    this.title = res.data.now;
                }else if(res.code == 2){
                    clearInterval(timer);
                    // Cookies.set('ntwl-token', res.data.token);
                    // Cookies.set('ntwl-uid', res.data.uid);
                    // Cookies.set('ntwl-authkey', res.data.authkey);
                    Cookies.set('ntwl-token', res.data.token,{domain: 'nituowola.com'});
                    Cookies.set('ntwl-uid', res.data.uid,{domain: 'nituowola.com'});
                    Cookies.set('ntwl-authkey', res.data.authkey,{domain: 'nituowola.com'});
                    //localStorage.setItem("ntwl-token",res.data.token);
                    window.location.href="https://dispatch.nituowola.com";
                }
            }
        },
        watch:{
            iscode(newval) {
                this.weicode = newval;
                this.getcodeurl();
            }
        },
    }
</script>

<style lang="less" scoped>
.scanBox{
    width: 1440px;
    height: 840px;
    margin-top: -840px;
    background: rgba(0, 0, 0, 0.4);
    position: relative;
}
.scanMain{
    width: 460px;
    height: 420px;
    background-color: #fff;
    border-radius: 12px;
    position: absolute;
    top: 198px;
    left: 490px;
}
.cuoImg{
    float: right;
    margin-top: 12px;
    margin-right: 12px;
    width: 24px;
    height: 24px;
}
.scanTitle{
    font-size: 18px;
    text-align: center;
    margin-top: 44px;
}
.scanMa{
    text-align: center;
}
.scanImg{
    width: 278px;
    height: 278px;
}
.scanHint{
    font-size: 16px;
    margin-top: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}
</style>