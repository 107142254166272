<template>
  <div class="loginBox">
    <img class="loginImg" src="../assets/img/Frame4388.png" alt="" />
    <div class="FromBox">
      <div class="FromMain">
        <div class="tabTop">
          <div @click="add = 1" :class="{ active: add === 1 }" class="fast">
            快捷登录
          </div>
          <div @click="add = 2" :class="{ active: add === 2 }" class="code">
            密码登录
          </div>
        </div>
        <div class="tabMain" v-show="add == 1">
          <span class="num">+86</span>
          <i class="xian"></i>
          <input
            class="tel"
            type="text"
            placeholder="请输入手机号"
            v-model="username"
            onfocus="this.removeAttribute('readonly')"
            readonly
          />
          <img @click="kong" class="cuoImg" src="../assets/img/cuo.png" alt="" />
          <input
            class="auth"
            type="text"
            placeholder="请输入验证码"
            v-model="code"
          />
          <span class="gain" v-if="sendcode == 0">获取验证码</span>
          <span class="gain-g" v-if="sendcode == 1" @click="sendsms"
            >获取验证码</span
          >
          <span class="gain" v-if="sendcode == 2">{{ time }}秒</span>
          <p class="error" v-if="error">{{ error }}</p>
        </div>
        <div class="tabMain1" v-show="add == 2">
          <input
            class="tel2"
            type="text"
            placeholder="请输入手机号"
            v-model="username"
            onfocus="this.removeAttribute('readonly')"
            readonly
          />
          <img
            @click="kong"
            class="cuoImg"
            src="../assets/img/cuo.png"
            alt=""
          />
          <input
            class="pass"
            :type="typePwd"
            placeholder="请输入密码"
            v-model="password"
            onfocus="this.removeAttribute('readonly')"
            readonly
          />
          <img
            @click="bi"
            class="eyeImg"
            src="../assets/img/bieye.png"
            alt=""
            v-show="eyes"
          />
          <img
            @click="zheng"
            class="eyeImg"
            src="../assets/img/eye.png"
            alt=""
            v-show="openeye"
          />
          <p class="error" v-if="error">{{ error }}</p>
          <div class="passBox">
            <!--<input
              type="checkbox"
              v-model="remember"
              @input="checkboxChange"
              ref="check"
            />
            <label class="bear" for="remember">记住密码</label>-->
            <span class="forget" @click="dead">忘记密码?</span>
          </div>
        </div>
        <!-- <a class="enter" href="">登录</a> -->
        <button class="enter" @click="skip">登录</button>
        <button class="codes" @click="WeChat">微信扫码登录</button>
        <div class="hint">
          <span class="hintTitle">没有帐号?</span>
          <router-link to="/sign" class="hintOnce">立即注册?</router-link>
          <!-- <span class="hintOnce">立即注册</span> -->
        </div>
      </div>
    </div>

    <forget :isshow="arr"></forget>
  </div>
  <scan :iscode="yare"></scan>
</template>

<script>
import * as api from "../api/index";
import Cookies from "js-cookie";

import forget from "../components/forget.vue";
import Scan from "../components/scan.vue";
export default {
  components: { forget, Scan },
  data() {
    return {
      add: 2,
      // isshow:1,
      arr: 0,
      yare: 0,
      username: "",
      password: "",
      remember: false,
      code: "",
      error: "",
      sendcode: 0,
      time: 0,
      timer: "",
      typePwd: "password",
      eyes: true,
      openeye: false,
    };
  },
  created() {
    let x = localStorage.getItem("ischecked") || false;
    console.log("初始化状态", x);
    this.remember = x;

    // let obj = localStorage.getItem("isRemember");
    // if (obj) {
    //   let newobj = JSON.parse(obj);
    //   this.username = newobj.uname;
    //   this.password = newobj.psw;
    // } else {
    //   this.username = "";
    //   this.password = "";
    // }
  },

  methods: {
    checkboxChange() {
      console.log("记住密码状态：", this.$refs.check.checked);
      // 保存本次是否记住密码状态
      localStorage.setItem("ischecked", this.$refs.check.checked);
      console.log("记住密码装态：", this.$refs.check.checked);
    },
    dead() {
      console.log("点击了：-----");
      this.arr++;
      if (this.arr == 3) {
        this.arr = 1;
      }
    },

    bi() {
      this.typePwd = this.typePwd === "password" ? "text" : "password";
      this.eyes = false;
      this.openeye = true;
    },
    zheng() {
      this.typePwd = this.typePwd === "password" ? "text" : "password";
      this.eyes = true;
      this.openeye = false;
    },

    kong() {
      this.username = "";
    },

    async skip() {
      let add = this.add;
      if (add == 1) {
        let type = 2;
        let username = this.username;
        let code = this.code;
        if (username == "" || username == undefined) {
          // alert('请输入用户名!');
          this.$notify.error({
            title: "错误",
            message: "请输入手机号",
          });
          return;
        }
        if (code == "" || code == undefined) {
          // alert('请输入验证码!');
          this.$notify.error({
            title: "错误",
            message: "请输入验证码!",
          });
          return;
        }
        let res = await api.login(type, username, "", code);
        console.log(res);
        if (res.code == 0) {
          this.error = res.message;
          return;
        } else {
          // Cookies.set("ntwl-token", res.data.token);
          // Cookies.set("ntwl-uid", res.data.uid);
          // Cookies.set("ntwl-authkey", res.data.authkey);
          Cookies.set("ntwl-token", res.data.token, {
            domain: "nituowola.com",
          });
          Cookies.set("ntwl-uid", res.data.uid, { domain: "nituowola.com" });
          Cookies.set("ntwl-authkey", res.data.authkey, {
            domain: "nituowola.com",
          });
          // localStorage.setItem("ntwl-token",res.data.token);
          window.location.href = "https://dispatch.nituowola.com";
          //window.location.href = "http://localhost:8080";
        }
      } else {
        let username = this.username;
        let password = this.password;
        let type = 1;

        if (username == "" || username == undefined) {
          // alert('请输入用户名!');
          this.$notify.error({
            title: "错误",
            message: "请输入手机号",
          });
          return;
        }
        if (password == "" || password == undefined) {
          // alert('请输入密码');
          this.$notify.error({
            title: "错误",
            message: "请输入密码",
          });
          return;
        }
        let res = await api.login(type, username, password, "");
        console.log(res);
        if (res.code == 0) {
          this.error = res.message;
          return;
        } else {
          Cookies.set("ntwl-token", res.data.token, {
            domain: "nituowola.com",
          });
          Cookies.set("ntwl-uid", res.data.uid, { domain: "nituowola.com" });
          Cookies.set("ntwl-authkey", res.data.authkey, {
            domain: "nituowola.com",
          });
          window.location.href = "https://dispatch.nituowola.com";
          // 根据是否记住密码来保存，删除密码
          if (this.remember) {
            let uname = this.username;
            let psw = this.password;
            let obj = {
              uname: uname,
              psw: psw,
            };

            localStorage.setItem("isRemember", JSON.stringify(obj));
          } else {
            let obj = {
              uname: this.username,
              psw: "",
            };
            localStorage.setItem("isRemember", JSON.stringify(obj));
          }
        }
      }
    },
    async sendsms() {
      let res = await api.sendsms(this.username);
      if (res.code == 1) {
        const TIME_COUNT = 60;
        if (this.time == 0) {
          this.time = TIME_COUNT;
          this.sendcode = 2;
          this.timer = setInterval(() => {
            if (this.time > 0 && this.time <= TIME_COUNT) {
              this.time--;
            } else {
              if (this.username.length == 11) {
                this.sendcode = 1;
              } else {
                this.sendcode = 0;
              }
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    WeChat() {
      this.yare++;
      if (this.yare == 3) {
        this.yare = 1;
      }
    },
  },
  watch: {
    username(newval) {
      this.error = "";
      if (this.time == 0) {
        if (newval.length == 11) {
          this.sendcode = 1;
        } else {
          this.sendcode = 0;
        }
      }
    },
    code(newVal) {
      this.error = "";
    },
    password(newval) {
      this.error = "";
    },
    add(newval) {
      this.error = "";
    },
  },
};
</script>

<style lang="less" scoped>
.loginBox {
  display: inline;
  // position: relative;
}
.loginImg {
  width: 800px;
  height: 840px;
  float: left;
}
.FromBox {
  background-color: #fff;
  height: 840px;
  width: 640px;
  display: flex;
  justify-content: center;
}
.tabTop {
  display: flex;
  cursor: pointer;
  height: 35px;
}
.FromMain {
  margin-top: 160px;
  width: 360px;
}
.tabMain,
.tabMain1 {
  //  height: 100px;
  margin-top: 24px;
  line-height: 35px;
  position: relative;
}
.num {
  position: absolute;
  top: 4px;
  left: 12px;
}
.fast {
  margin-right: 24px;
  line-height: 35px;
}
.code {
  line-height: 35px;
}
.active {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  border-bottom: 2px solid #2c68ff;
  padding-bottom: 40px;
  font-weight: bold;
}
.xian {
  width: 1px;
  height: 12px;
  background-color: #c4c4c4;
  position: absolute;
  top: 15px;
  left: 52px;
}
.cuoImg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 13px;
  right: 13px;
}
.eyeImg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 80px;
  right: 13px;
  cursor: pointer;
}
.tel,
.auth,
.tel2,
.pass {
  width: 360px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
}
.tel {
  padding-left: 100px;
  border-radius: 8px;
}
.tel2 {
  padding-left: 12px;
  border-radius: 8px;
}
.pass {
  margin-top: 24px;
  padding-left: 12px;
  border-radius: 8px;
}
input {
  outline: none;
}
.auth {
  width: 245px;
  padding-left: 12px;
  margin-top: 24px;
  border-radius: 8px;
}
.gain {
  width: 102px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 65px;
  right: 0;
  box-sizing: border-box;
  color: #fff;
}
.gain-g {
  width: 102px;
  height: 38px;
  text-align: center;
  background-color: #2c68ff;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 65px;
  right: 0;
  box-sizing: border-box;
  color: #fff;
}
.enter,
.codes {
  width: 360px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  margin-top: 24px;
  cursor: pointer;
}
.enter {
  color: #fff;
  background-color: #2c68ff;
  border: 0;
}
.codes {
  background-color: #fff;
  border: 1px solid #2c68ff;
  color: #2c68ff;
}
.hint {
  margin-top: 24px;
  text-align: center;
}
.hintTitle,
.hintOnce {
  cursor: pointer;
}
.hintTitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}
.hintOnce {
  font-size: 14px;
  color: #2c68ff;
}
.passBox {
  margin-top: 10px;
}
.bear,
.forget {
  font-size: 14px;
  cursor: pointer;
}
.bear {
  margin-left: 5px;
  vertical-align: top;
}
.forget {
  float: right;
}
.error {
  font-size: 14px;
  color: red;
  //display: inline-block;
}
</style>